import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import useBottomNav from "@/hooks/Library/useBottomNav";
import { useArrowKeyNavigation } from "@/hooks/Library/useArrowKeyNavigation";
import { useTrackEvent } from "@/services";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";
import Link from "@/components-modern/atoms/link/Link";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkGroup from "@/components-modern/molecules/link-group/LinkGroup";
import { useRouter } from "next/router";
import GenericDialog from "@/components-modern/molecules/generic-dialog/GenericDialog";
import ContentHeadline from "@/components/Library/Resources/ContentHeadline";

// this preserves existing functionality to scroll to the pep quiz sometimes
const getLinkProps = (href) => {
  const isSectionLink = href?.indexOf("#") === -1;
  const scroll = !isSectionLink;
  const replace = !!isSectionLink;
  return { scroll, replace };
};

interface BottomNavDesktopProps {
  pageData?: {
    practiceMore?: boolean;
    practiceMoreText?: string;
    practiceUrl?: string | null;
    hasKeyTerms?: boolean;
    practiceEventProps?: {
      numOfQuestions: number;
    };
    [key: string]: unknown;
  };
  hasPracticeQuestions?: boolean;
  isSidebarOpen: boolean;
}

const GuidesDialog = ({ open, handleClose }): JSX.Element => {
  return (
    <GenericDialog
      open={open}
      onCancel={handleClose}
      id="mobile-guides-dialog"
      dialogContentStyles={{
        justifyContent: "flex-start",
        flexDirection: "column",
      }}>
      <ContentHeadline />
    </GenericDialog>
  );
};

// eslint-disable-next-line max-lines-per-function
const BottomNavDesktop = ({
  pageData,
  hasPracticeQuestions,
  isSidebarOpen,
}: BottomNavDesktopProps): JSX.Element => {
  const { trackEvent } = useTrackEvent();
  const [open, setOpen] = useState(false);
  const { linkGroupLinksDesktop, linkGroupLinksMobile, nextPrevLinkInfo } = useBottomNav({
    pageData,
    hasPracticeQuestions,
  });

  useArrowKeyNavigation();
  const prevLinkProps = getLinkProps(nextPrevLinkInfo.prev.href);
  const nextLinkProps = getLinkProps(nextPrevLinkInfo.next.href);
  const nextLink = nextPrevLinkInfo.next.href;
  const prevLink = nextPrevLinkInfo.prev.href;
  const nextIsEnabled = nextPrevLinkInfo.next.isEnabled;
  const prevIsEnabled = nextPrevLinkInfo.prev.isEnabled;

  const trackClick = ({ eventLabel, eventProps = {} }) => {
    trackEvent({
      ...navbarAnalyticsInfo.CLICK_BOTTOM_NAV_LINK,
      label: eventLabel,
      other: eventProps,
    });
  };
  // don't show bottom nav on homepage
  const router = useRouter();
  const hasNoLinkGroupLinks = !linkGroupLinksDesktop?.length || !linkGroupLinksMobile?.length;

  // close modal when url
  useEffect(() => {
    setOpen(false);
  }, [router?.asPath]);

  const noLinks = !nextPrevLinkInfo.next.href && !nextPrevLinkInfo.prev.href && hasNoLinkGroupLinks;
  if (router?.asPath === "/" || noLinks) return null;

  let nextLabel = "Next guide";
  let prevLabel = "Back";
  if (!isSidebarOpen) {
    nextLabel = nextPrevLinkInfo.next.label;
    prevLabel = nextPrevLinkInfo.prev.label;
  }

  const chatBubbleOffset = isSidebarOpen ? 0 : 50;
  // when sidebar closed on desktop, we have room to show guide labels
  // mobile will never show these, so only need room for an arrow
  const nextPrevWidth = isSidebarOpen ? "10%" : { xs: "10%", md: "30%" };
  const linkGroupWidth = isSidebarOpen ? "80%" : { xs: "80%", md: "30%" };

  return (
    <Box
      data-testid="BottomNav"
      sx={(theme) => ({
        background: theme.palette.background.paper,
        borderTop: `2px solid ${theme.palette.divider}`,
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 1000,
        width: { xs: "100%", md: isSidebarOpen ? "calc(100vw - 350px)" : "100%" },
        paddingY: 1,
        transition: theme.transitions.create(["width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      })}>
      <Box
        sx={{
          paddingRight: { xs: "15px", sm: "20px", md: "40px" }, // bottom nav button lines up with reading practice/guide content at this width
          paddingLeft: {
            xs: `15px`,
            sm: `${20 + chatBubbleOffset}px`,
            md: `${40 + chatBubbleOffset}px`,
          }, // bottom nav button lines up with reading practice/guide content at this width
          width: "100%",
          display: "flex",
          gap: {
            xs: "8px",
            md: "0",
          },
          justifyContent: nextIsEnabled && prevIsEnabled ? "space-between" : "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: nextPrevWidth,
          }}>
          <Link
            href={prevLink || ""}
            id="prevLink"
            variant="text"
            pointerEvents={prevIsEnabled && prevLink ? "auto" : "none"}
            linkStyles={{
              visibility: prevIsEnabled && prevLink ? "visible" : "hidden",
            }}
            onClick={() =>
              trackClick({
                eventLabel: prevLabel,
              })
            }
            {...prevLinkProps}>
            <Stack display="flex" alignItems="center" flexDirection="row">
              <Stack flexDirection="row" justifyContent="center" alignItems="center" width=".5rem" mr={1}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ width: "100%" }} />
              </Stack>
              <Typography variant="subtitle2" sx={{ display: { xs: "none", md: "flex" } }}>
                {prevLabel}
              </Typography>
            </Stack>
          </Link>
        </Box>
        {/* mobile bottom nav, done this way to avoid relying on useMediaQuery which can introduce flickering UI */}
        <Stack flexDirection="row" justifyContent="center" alignItems="center" width={linkGroupWidth}>
          {/* Mobile bottom nav */}
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <GuidesDialog open={open} handleClose={() => setOpen(false)} />
            <LinkGroup
              links={linkGroupLinksMobile}
              linkVariant="contained"
              onClick={(l) => {
                if (l.id === "practiceMore" && !hasPracticeQuestions) {
                  l.onClick?.();
                } else if (l.id === "guideBottomNavLink") {
                  setOpen(true);
                } else {
                  trackClick({
                    eventLabel: l.label,
                    eventProps: { ...(pageData?.practiceEventProps || {}) },
                  });
                }
              }}
            />
          </Box>
          {/* Desktop bottom nav */}
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <LinkGroup
              links={linkGroupLinksDesktop}
              linkVariant="contained"
              onClick={(l) => {
                if (l.id === "practiceMore" && !hasPracticeQuestions) {
                  l.onClick?.();
                } else {
                  trackClick({
                    eventLabel: l.label,
                    eventProps: { ...(pageData?.practiceEventProps || {}) },
                  });
                }
              }}
            />
          </Box>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" alignItems="center" width={nextPrevWidth}>
          <Link
            href={nextLink || ""}
            variant="primary"
            id="nextLink"
            pointerEvents={nextIsEnabled && nextLink ? "auto" : "none"}
            linkStyles={{
              visibility: nextIsEnabled && nextLink ? "visible" : "hidden",
            }}
            onClick={() =>
              trackClick({
                eventLabel: nextLabel,
              })
            }
            {...nextLinkProps}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0, md: 1 }}
              sx={{
                minWidth: 0, // Allow the Stack to shrink below its content size
                overflow: "hidden", // Hide overflowing content
              }}>
              <Typography
                variant="subtitle2"
                sx={{
                  display: { xs: "none", md: "block" },
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Show ellipsis for overflowing text
                  flexShrink: 1, // Allow the Typography to shrink
                  minWidth: 0, // Allow the Typography to shrink below its content size
                }}>
                {nextLabel}
              </Typography>
              <Stack
                width=".5rem"
                ml={1}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexShrink={0} // Prevent the icon from shrinking
              >
                <FontAwesomeIcon icon={faChevronRight} style={{ width: "100%" }} />
              </Stack>
            </Stack>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default BottomNavDesktop;
