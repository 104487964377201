import React, { useState, useEffect } from "react";
import SubjectsAutocompleteInput from "@/components/Library/NewCustomEditor/SubjectsAutocompleteInput";
import { Form, Formik } from "formik";
import { Typography } from "@mui/material";
import { Button } from "@/components-modern/atoms/button/Button";
import { ISubjectAutocompleteSubject } from "@/types";
import { getSearchableSubjects } from "@/components-modern/molecules/subject-search/SubjectSearch";
import { useModernDispatch } from "@/store/hooks";
import { useRouter } from "next/router";
import useUpdateQueryParams from "@/hooks/useUpdateQueryParams";
import { useTrackEvent } from "@/services";
import { CLICK_SAVE_SUBJECT_TO_PROFILE } from "@/utils/analyticsConstants/genericAnalyticsInfo";
import { useAuthModals } from "../../../hooks/modals/useAuthModals";
import GenericDialog from "../generic-dialog/GenericDialog";

export const ClassSelectModal = ({
  subjects,
  setSubjects,
  allSubjects = [],
  isLoading,
  onSubmit,
  open,
  handleClose,
}: {
  subjects: ISubjectAutocompleteSubject[];
  setSubjects: (newSubjects: ISubjectAutocompleteSubject[]) => void;
  onSubmit: (values: { subjects: ISubjectAutocompleteSubject[] }, skipToLogin?: boolean) => void;
  allSubjects?: ISubjectAutocompleteSubject[];
  isLoading: boolean;
  open: boolean;
  handleClose: () => void;
}): JSX.Element => {
  return (
    <GenericDialog
      showCloseButton
      open={open}
      onCancel={handleClose}
      id="mobile-guides-dialog"
      dialogContentStyles={{
        justifyContent: "flex-start",
        flexDirection: "column",
      }}>
      <Typography variant="h4">hey what classes are you taking this year?</Typography>
      <Formik initialValues={{ subjects }} onSubmit={(values) => onSubmit(values, false)}>
        <Form>
          <SubjectsAutocompleteInput
            isLoading={isLoading}
            newSubjects={subjects}
            setNewSubjects={setSubjects}
            initialSubjects={allSubjects}
            placeholder={isLoading ? "Loading..." : undefined}
          />
          <Button onClick={() => onSubmit({ subjects })} variant="secondary">
            save subjects
          </Button>
        </Form>
      </Formik>
      <Button variant="text" sx={{ mt: 2 }} onClick={() => onSubmit({ subjects }, true)}>
        I&apos;ve already selected my classes, log me in
      </Button>
    </GenericDialog>
  );
};

const getSubjectSlug = (router) => {
  const { communitySlug, subjectSlug, slug } = router.query;
  return communitySlug || subjectSlug || slug?.[0];
};

const WrappedClassSelect = (): JSX.Element => {
  const dispatch = useModernDispatch();
  const router = useRouter();
  const subjectSlug = getSubjectSlug(router);
  const { showExpandedSignupModal, showExpandedLoginModal } = useAuthModals();
  const { updateQueryParams } = useUpdateQueryParams();
  const { trackEvent } = useTrackEvent();

  const [allSubjects, setAllSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (values, skipToLogin) => {
    updateQueryParams({
      queryParams: [
        { paramName: "saving-subjects", paramValue: "true" },
        {
          paramName: "subjects",
          paramValue: subjects.map((subject) => subject.id).join(","),
        },
      ],
    });
    if (skipToLogin) {
      showExpandedLoginModal({ type: "cheatsheet" });
      handleClose();
      return;
    }
    // eslint-disable-next-line no-console
    console.log("values", values);
    // add url param to save subjects
    showExpandedSignupModal({ type: "cheatsheet" });
    handleClose();
  };

  useEffect(() => {
    if (!open) return;
    setIsLoading(true);
    getSearchableSubjects({ dispatch })
      .then((res) => {
        const searchableSubjects = res.data.getSearchableSubjects;
        const currentSubject = searchableSubjects.find((subject) => subject.slug === subjectSlug);
        const currentAutoCompleteSubject = {
          ...currentSubject,
          label: `${currentSubject.emoji} ${currentSubject.name} `,
        };
        if (currentSubject) {
          setSubjects([currentAutoCompleteSubject]);
        }
        setAllSubjects(res.data.getSearchableSubjects);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [open]);

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          trackEvent({
            ...CLICK_SAVE_SUBJECT_TO_PROFILE,
            other: {
              subjectId: subjects[0]?.id,
              isLoggedIn: false,
            },
          });
          setOpen(true);
        }}>
        Save subject
      </Button>
      <ClassSelectModal
        subjects={subjects}
        allSubjects={allSubjects}
        isLoading={isLoading}
        setSubjects={setSubjects}
        onSubmit={onSubmit}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default WrappedClassSelect;
